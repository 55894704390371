<!-- 动态表单组件 -->
<script>
import EditItemSelect from '@/components/edit/edit-item-select.vue'
export default {
  components: { EditItemSelect },
  data() {
    return {
      flex: {
        type: 'flex',
        justify: 'space-between',
        align: 'middle'
      },
    }
  },
  props: {
    /**
     * 指定vue中的key，建议指定为id
     */
    rowKey: {
      type: String,
      default: () => '_key',
    },
    /**
     * 指定动态表单表头和列
     */
    column: {
      type: Array,
      default: () => [
        { title: 'name', label: 'name', placeholder: 'Please input name' },
        {
          title: 'value', label: 'value', placeholder: 'Please select value', select: [
            { value: '0', label: 'yes' },
            { value: '1', label: 'no' },
          ]
        },
      ],
    },
    /**
     * 显示label
     */
    showLabel: {
      type: Boolean,
      default: () => false,
    },
    /**
     * 是否预览模式
     */
    isPreview: {
      type: Boolean,
      default: () => false,
    },
    /**
     * 数据，非受控组件可用v-model或者value绑定，传入的数据会随着用户操作而更新
     */
    value: {
      type: Array,
      default: () => [],
    },
    /**
     * 表单元素布局式样
     */
    formItemLayout: {
      type: Object,
      default: () => ({
        /* labelCol: {
          style: 'width: 120px',
          span: 8
        },
        wrapperCol: { span: 16 }, */
        colon: false,
      }),
    },
    /**
     * 表单blur事件
     */
    blur: {
      type: Function,
      default: () => () => { },
    },
    /**
     * 默认值
     */
    defaultValue: {
      type: Array,
      default: () => []
    },
    /**
     * 动态表单添加一行
     */
    additional: {
      type: [Array, Function, Object],
      default: () => []
    },
    /**
     * 每一行额外附带的表单项
     */
    attachFormItem: {
      type: Object,
      default: () => null
    }
  },
  created() {
  },
  mounted() {
    const additional = this.getAdditional
    let defaultList = [];
    if (this.defaultValue?.length) {
      defaultList = this.defaultValue;
    } else if (additional?.length) {
      defaultList = additional;
    }
    let dataList = [...this.value, ...defaultList]
    console.log({ dataList });
    if (!dataList.length) {
      dataList = [this.genItem(Math.random())]
    }
    dataList.map(item => {
      return { columns: item.columns ? item.columns : this.column, ...item };
    });
    console.log({ dataList });
    this.$emit('input', dataList);
  },
  computed: {
    /**
     * 获取过滤后的items
     */
    getItems() {
      return this.value.filter((item) =>
        this.column.some(({ title }) => item[title] != null && item[title] != undefined && item[title] != '')
      )
    },
    getAdditional() {
      let additional = typeof this.additional === 'function' ? this.additional() : this.additional
      if (additional === null || additional === undefined) {
        return [];
      }
      additional = Array.isArray(additional) ? additional : [additional]
      return additional;
    }
  },
  methods: {
    addItem(index) {
      let [item] = this.genItem()
      console.log({ item });
      let itemList
      if (index != null && index != undefined) {
        itemList = [...this.value]
        itemList.splice(index + 1, 0, item)
      } else {
        itemList = [...this.value, item]
      }
      this.$emit('input', itemList)
      this.$emit('addItem', index)
    },
    delItem(index) {
      let itemList = [...this.value]
      if (itemList.length == 1 || itemList[index]?.notDelete) {
        let item = {
          ...itemList[index],
          ...Object.fromEntries(this.column.map(({ title }) => [title, undefined])),
        }
        itemList.splice(index, 1, item)
      } else {
        itemList.splice(index, 1)
        this.$emit('delItem', index)
      }
      this.$emit('input', itemList)
    },
    selectChange(value, index, column) {
      console.log('selectChange', { value, index, column });

      let list = [...this.value]
      let item = { ...list[index], [column.title]: value }
      list.splice(index, 1, item)
      this.$emit('input', list)
      console.log({ list });

      column.change && column.change(value, index)
    },
    genItem(key) {
      const additional = this.getAdditional
      if (additional?.length) {
        return additional.map(item => {
          return {
            ...item,
            [this.rowKey]: key ? key : Math.random(),
          }
        })
      }
      return Object.fromEntries(
        [...this.column, { title: this.rowKey, value: key || Math.random() }].map(({ title, value }) => [
          title,
          value || undefined,
        ])
      )
    },
    watchValue() {
      this.$emit('change', this.getItems)
    },
  },
  watch: {
    value: {
      deep: true,
      handler: 'watchValue',
    },
  },
  render() {
    const renderInput = (item, index, columnItem, columnIndex) => {
      if (columnItem.select) {
        if (this.isPreview) { return <div>{(columnItem.select.find(({ value }) => value == item[columnItem.title]) || {}).label}</div> }
        console.log('renderInput', { item, columnItem });
        return columnItem.canAddOption ?
          <edit-item-select vModel={item[columnItem.title]} placeholder={columnItem.placeholder} /> :
          <a-select vModel={item[columnItem.title]}
            options={columnItem.select}
            placeholder={columnItem.placeholder}
            vOn:change={(value) => this.selectChange(value, index, columnItem)}
            vOn:blur={this.blur}
            allow-clear
            show-search />
      } else if (columnItem.radio) {
        return this.isPreview ?
          <div>{(columnItem.radio.find(({ value }) => value == item[columnItem.title]) || {}).label}</div> :
          <a-radio-group vModel={this.value[index][columnItem.title]}
            options={columnItem.radio}
            vOn:change={($event) => this.selectChange($event.target.value, index, columnItem)}
            vOn:blur={this.blur} />
      } else {
        const inputEdit = <a-input vOn:blur={this.blur} vModel={item[columnItem.title]} placeholder={columnItem.placeholder} allow-clear />

        return this.isPreview ?
          <div>{item[columnItem.title]}</div> :
          (item[columnItem.title]?.length > 20 ?
            <a-tooltip scopedSlots={{ title: () => item[columnItem.title] || columnItem.placeholder }}>{inputEdit}</a-tooltip> :
            inputEdit)
      }
    }

    const renderFormItem = (item, index) => (
      <span class='contents'>
        <a-form-model-item
          label={this.showLabel && item.label}
          {...{ attrs: this.showLabel && this.formItemLayout }}>
          {console.log('renderFormItem', { item, index })}
          {(item.columns && item.columns.length) &&
            <a-row {...{ attrs: this.flex }}>
              {item.columns.map((columnItem, columnIndex) =>
                <a-col class="contents" key={item.title || columnIndex}>
                  {renderInput(item, index, columnItem, columnIndex)}
                </a-col>
              )}
              <span class="item-btns">
                {this.isPreview || !item.canAdd ||
                  <span class='contents'>
                    <a-button class="item-btn" shape="circle" size="small" icon="minus" vOn:click={e => this.delItem(index)} />
                    <a-button class="item-btn" shape="circle" size="small" icon="plus" vOn:click={e => this.addItem(index)} />
                  </span>
                }
              </span>
            </a-row>}
        </a-form-model-item>
      </span>)

    console.log({ 'this.value': this.value });

    return (
      <div class="contents">
        {this.value.map((item, index) =>
          <div class="contents" key={item[this.rowKey] || index}>
            {renderFormItem(item, index)}
            {/* console.log({ item }) */}
            {/* console.log({ 'item[item.columns[1].title]': item[item.columns[1].title] }) */}
            {/* console.log({ 'item.columns[1].select?.find(({ value }) => value == item[item.columns[1].title])': item.columns[1].select?.find(({ value }) => value == item[item.columns[1].title]) }) */}
            {item.type == 'SELECT' && renderFormItem(item.columns[1].select?.find(({ value }) => value == item[item.columns[1].title]), index)}
            {this.attachFormItem && renderFormItem(this.attachFormItem, index)}
          </div>
        )}
      </div>
    )
  }
}
</script>

<style lang="stylus" scoped>
.item-btns {
  display: flex;
  margin-top: 4px;
  min-width: 68px;

  .item-btn {
    margin-left: 10px;
  }
}
/deep/ .ant-input-affix-wrapper,.ant-select{
  flex: 1;
  position: relative;
  top: 3px;
}
/deep/ .ant-radio-group{
position: relative;
  top: 8px;
}
/deep/ .select-box{
  flex: 1;
}
</style>